import Repository from "@/repositories/RepositoryFactory";

const SecurityRepository = Repository.get("SecurityRepository");

export const VerifyRecaptchaAsync = async data => {
  try {
    const response = await SecurityRepository.VerifyRecaptcha(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const AuthenticateMyWgaUserAsync = async data => {
  try {
    const response = await SecurityRepository.AuthenticateMyWgaUser(data);
    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
