<template>
  <div id="tpl-contact" class="template-contact-info-3">
    <b-container>
      <div class="text-center">
        <h3>Get In Touch</h3>
      </div>
      <b-form @submit.prevent="onSubmit">
        <b-form-group
          id="contact-name"
          class="form-input"
          label="Name"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            type="text"
            placeholder="Enter Name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="contact-email"
          class="form-input"
          label="Email"
          label-for="input-email"
        >
          <b-form-input
            id="input-email"
            v-model="form.email"
            type="email"
            placeholder="Enter Email"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="contact-number"
          class="form-input"
          label="Phone"
          label-for="input-number"
        >
          <b-form-input
            id="input-number"
            v-model="form.phone"
            type="tel"
            placeholder="Enter Number"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-textarea
          id="contact-textarea"
          v-model="form.message"
          placeholder="Enter Something..."
        ></b-form-textarea>
        <b-button :disabled="submitting" type="submit">
          <b-spinner small v-if="submitting"></b-spinner>
          <span class="ml-3" v-if="submitting">{{ statusText }}</span>
          <span v-else>{{ statusText }}</span>
        </b-button>
      </b-form>
    </b-container>
    <div id="social-links" v-if="candidate.socialLinks">
      <b-container class="social-wrapper">
        <a
          :href="FormatSocialLink(candidate.socialLinks.facebookLink)"
          target="_blank"
          class="social-link"
          v-if="candidate.socialLinks.facebookLink"
          ><font-awesome-icon :icon="['fab', 'facebook-f']"
        /></a>
        <a
          :href="FormatSocialLink(candidate.socialLinks.twitterLink)"
          target="_blank"
          class="social-link"
          v-if="candidate.socialLinks.twitterLink"
          ><font-awesome-icon :icon="['fab', 'twitter']"
        /></a>
        <a
          :href="FormatSocialLink(candidate.socialLinks.instagramLink)"
          target="_blank"
          class="social-link"
          v-if="candidate.socialLinks.instagramLink"
          ><font-awesome-icon :icon="['fab', 'instagram']"
        /></a>
        <a
          :href="FormatSocialLink(candidate.socialLinks.linkedInLink)"
          target="_blank"
          class="social-link"
          v-if="candidate.socialLinks.linkedInLink"
          ><font-awesome-icon :icon="['fab', 'linkedin']"
        /></a>
        <a
          :href="FormatSocialLink(candidate.socialLinks.personalWebsiteLink)"
          target="_blank"
          class="social-link"
          v-if="candidate.socialLinks.personalWebsiteLink"
          ><font-awesome-icon :icon="['fas', 'globe']"
        /></a>

        <a
          :href="FormatSocialLink(candidate.socialLinks.fawLink)"
          target="_blank"
          class="social-link"
          v-if="candidate.socialLinks.fawLink"
          ><font-awesome-icon :icon="['fas', 'globe']"
        /></a>
      </b-container>
    </div>
  </div>
</template>

<script>
import RecaptchaMixin from "@/mixins/recaptcha.mixin";
import * as CandidateService from "@/services/CandidateService2";
import { FormatSocialLink } from "@/services/helpers";

export default {
  mixins: [RecaptchaMixin],
  data() {
    return {
      title: "Contact Info",
      form: {
        name: null,
        email: null,
        phone: null,
        message: null,
        candidateId: null,
      },
      submitting: false,
      statusText: "send message",
    };
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    },
  },
  methods: {
    FormatSocialLink: FormatSocialLink,
    onSubmit() {
      this.submitting = true;
      this.statusText = "submitting...";
      this.form.candidateId = this.candidate.id;

      this.recaptchaCallback().then((res) => {
        if (!res) {
          this.submitting = false;
          this.statusText = "send message";
          alert("Verification failed! Try again later.");
        } else {
          CandidateService.SubmitEmailAsync(this.form)
            .then((res) => {
              this.form = {};
            })
            .catch((e) => console.error(e))
            .finally(() => {
              this.submitting = false;
              this.statusText = "send message";
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
