import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import * as SecurityService from "@/services/SecurityService";

Vue.use(VueReCaptcha, { siteKey: process.env.RECAPTCHA_SITE_KEY });

export default {
  methods: {
    recaptchaCallback() {
      return this.$recaptcha("login").then(token => {
        return SecurityService.VerifyRecaptchaAsync(token).then(res => {
          let result = false;

          if (res.data.success && parseFloat(res.data.score) >= 0.7)
            result = true;

          return result;
        });
      });
    }
  }
};
